<template>
  <div id="homeIndex">
    <!-- 企业工作台 -->
    <div class="qiyeJob" id="companyList">
      <div class="titls">
        <span>企业工作台</span>
        <span @click="regUserBtn"><i class="el-icon-plus"></i> 创建企业</span>
      </div>
      <p class="myAdmin">
        <span>你在以下企业担任管理员</span>
      </p>
      <div
        class="adminsQi"
        v-for="item in isAdminList"
        :key="item.id"
        @click="goToPage(item)"
      >
        <!-- <el-tooltip content="left" placement="left" effect="light"> -->
        <div class="icon-company">
          <svg
            width="26"
            height="26"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24 33V15"
              stroke="#4387f8"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <rect
              x="10"
              y="9"
              width="28"
              height="6"
              fill="#4387f8"
              stroke="#4387f8"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8 32L14 25H33.9743L40 32"
              stroke="#4387f8"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <rect
              x="4"
              y="33"
              width="8"
              height="8"
              fill="#4387f8"
              stroke="#4387f8"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <rect
              x="20"
              y="33"
              width="8"
              height="8"
              fill="#4387f8"
              stroke="#4387f8"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <rect
              x="36"
              y="33"
              width="8"
              height="8"
              fill="#4387f8"
              stroke="#4387f8"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <!-- </el-tooltip> -->
        <div>
          <!-- <div>
            <span>企业号：{{ item.company_id }}</span>
          </div> -->
          <div>
            <span>{{ item.company_name }}</span>
            <el-tag
              v-if="item.is_main == 1"
              style="margin-left: 5px"
              size="mini"
              type="danger"
              >主企业</el-tag
            >
          </div>
        </div>
        <div>
          <div>
            <span
              v-if="item.is_main == 0"
              class="main-btn"
              @click.stop="setZhu(item)"
              >设为主企业</span
            >
          </div>
          <span> </span>
          <i class="el-icon-right iconsd"></i>
        </div>
      </div>

      <p v-if="staffList.length != 0" class="myAdmin">
        <span>你在以下企业担任员工</span>
      </p>
      <div
        class="adminsQi"
        v-for="item in staffList"
        :key="item.id"
        @click="goToPage(item)"
      >
        <div class="icon-company">
          <svg
            width="26"
            height="26"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24 33V15"
              stroke="#4387f8"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <rect
              x="10"
              y="9"
              width="28"
              height="6"
              fill="#4387f8"
              stroke="#4387f8"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8 32L14 25H33.9743L40 32"
              stroke="#4387f8"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <rect
              x="4"
              y="33"
              width="8"
              height="8"
              fill="#4387f8"
              stroke="#4387f8"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <rect
              x="20"
              y="33"
              width="8"
              height="8"
              fill="#4387f8"
              stroke="#4387f8"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <rect
              x="36"
              y="33"
              width="8"
              height="8"
              fill="#4387f8"
              stroke="#4387f8"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div>
          <!-- <div>
            <span>企业号：{{ item.company_id }}</span>
          </div> -->
          <div>
            <span>{{ item.company_name }}</span>
            <el-tag
              v-if="item.is_main == 1"
              style="margin-left: 5px"
              size="mini"
              type="danger"
              >主企业</el-tag
            >
          </div>
        </div>
        <div>
          <div>
            <span
              v-if="item.is_main == 0"
              class="main-btn"
              @click.stop="setZhu(item)"
            >
              设为主企业</span
            >
          </div>
          <i class="el-icon-right iconsd"></i>
        </div>
      </div>
    </div>

    <el-dialog
      title="工作台首次登录须修改密码"
      :visible.sync="loginCode.showLog"
      width="550px"
    >
      <div style="margin: 0 10px">
        <el-form
          size="small"
          ref="changeForm"
          :model="loginCode.datas"
          :rules="loginRules"
        >
          <el-form-item prop="first_pwd">
            <el-input
              clearable
              show-password
              style="width: 400px"
              v-model="loginCode.datas.first_pwd"
              placeholder="初始密码"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              clearable
              show-password
              style="width: 400px"
              v-model="loginCode.datas.password"
              placeholder="新密码"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password_confirm">
            <el-input
              clearable
              show-password
              style="width: 400px"
              size="small"
              v-model="loginCode.datas.password_confirm"
              placeholder="确认新密码"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <el-row slot="footer">
        <el-col align="center">
          <el-button
            size="small"
            style="width: 88px"
            @click="loginCode.showLog = false"
            >取 消</el-button
          >
          <el-button
            size="small"
            style="width: 88px"
            type="primary"
            @click="loginCode.submit"
            >确 定</el-button
          >
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import eventBus from "@/server/eventBus.js";
export default {
  data() {
    let _this = this;
    let loginRules = {
      first_pwd: [
        {
          trigger: "blur",
          validator: (rules, value, call) => {
            if (!value) {
              call(new Error("请输入初始密码"));
            } else {
              call();
            }
          },
        },
      ],
      password: [
        {
          trigger: "blur",
          validator: (rules, value, call) => {
            let regPwd = /(?=.*[0-9])(?=.*[a-zA-Z]).{8,16}/;
            if (!value) {
              call(new Error("请输入新密码"));
            } else if (!regPwd.test(value)) {
              call(new Error("密码须包含数字和字母"));
            } else {
              call();
            }
          },
        },
      ],
      password_confirm: [
        {
          trigger: "blur",
          validator: (rules, value, call) => {
            let regPwd = /(?=.*[0-9])(?=.*[a-zA-Z]).{8,16}/;
            if (!value) {
              call(new Error("请输入确认密码"));
            } else if (_this.loginCode.datas.password != value) {
              call(new Error("两次密码不相同，请重新输入"));
            } else if (!regPwd.test(value)) {
              call(new Error("确认密码须包含数字和字母"));
            } else {
              call();
            }
          },
        },
      ],
    };
    return {
      isAdminList: [],
      staffList: [],
      adminInfo: {},
      staffInfo: {},
      userAvatar: "",
      userInfo: {},
      loginRules,
      loginCode: {
        showLog: false,
        company_id: null,
        step: 1,
        datas: {
          company_id: "",
          first_pwd: "",
          password: "",
          password_confirm: "",
        },
        open: (val) => {
          const login = this.loginCode;
          login.step = 1;
          login.datas.first_pwd = "";
          login.datas.password = "";
          login.datas.password_confirm = "";
          login.company_id = val;
          login.showLog = true;
        },
        contentniue: () => {
          if (this.loginCode.datas.first_pwd) {
            this.loginCode.step = 2;
          } else {
            this.$message.error("请先输入初始密码");
          }
        },
        submit: () => {
          if (
            this.loginCode.datas.password &&
            this.loginCode.datas.password_confirm
          ) {
            if (
              this.loginCode.datas.password ==
              this.loginCode.datas.password_confirm
            ) {
              let param = {
                ...this.loginCode.datas,
                company_id: this.loginCode.company_id,
              };
              this.$axios
                .postJSON("/base/user/company/resetpwd", param)
                .then((res) => {
                  if (res.code == 200) {
                    this.goToPage({ company_id: this.loginCode.company_id });
                    this.loginCode.showLog = false;
                  }
                });
            } else {
              this.$message.error("两次密码不一致，请重新输入");
            }
          } else {
            this.$message.error("请先输入密码");
          }
        },
      },
    };
  },
  created() {
    this.query();
    this.$store.commit("changeChoseType", 1);
  },

  methods: {
    myCompanyBtn() {
      document.querySelector("#companyList").scrollIntoView({
        block: "end",
        behavior: "smooth",
      });
    },
    sumbitFormData() {
      //用户首次登录工作台修改密码
      this.$refs.changeForm.validate((res) => {
        if (res) {
          let param = {
            ...this.loginCode.datas,
            company_id: this.loginCode.company_id,
          };
          this.$axios
            .postJSON("/base/user/company/resetpwd", param)
            .then((res) => {
              if (res.code == 200) {
                this.goToPage({ company_id: this.loginCode.company_id });
              }
            });
        }
      });
    },
    regUserBtn() {
      let url = this.$router.resolve({
        path: "/register/settled",
      });
      window.open(url.href, "_blank");
    },
    setZhu(val) {
      this.$axios
        .postJSON("/base/user/company/setmain", {
          company_id: Number(val.company_id),
        })
        .then((res) => {
          if (res.code == 200) {
            eventBus.$emit("mainCompany", val);
            this.$message.success("设置成功");
            this.query();
          }
        });
    },
    getUserInfo() {
      this.$axios.get("/base/user/login_info").then((res) => {
        if (res.code == 200) {
          let data = res.data;
          this.userAvatar = `https://avatar.${VUE_APP_API_URL}/user/avatar/${data.user_id}`;
          this.staffInfo = data.staff;
          this.adminInfo = data.company;
          this.userInfo = data;
        }
      });
    },
    goToPage(item) {
      this.$axios
        .postJSON("/base/user/login/company", {
          company_id: Number(item.company_id),
        })
        .then((res) => {
          if (res.code == 200) {
            if (res.data.company_id) {
              this.loginCode.open(res.data.company_id);
            } else {
              if (process.env.NODE_ENV === "production") {
                sessionStorage.setItem("AccessToken", res.data.AccessToken);
                
                localStorage.setItem(
                  "time_token",
                  res.data.AccessExpire * 1000
                );
                localStorage.setItem('loading', 'going')
              } else {
                localStorage.setItem("AccessToken", res.data.AccessToken);
              }
              window.open("/main");
            }
          }
        });
    },
    query() {
      this.$axios.get("/base/user/company").then((res) => {
        if (res.code == 200) {
          let array = res.data;
          this.isAdminList = array.filter((res) => {
            return res.is_admin == 1;
          });
          this.staffList = array.filter((res) => {
            return res.is_admin != 1;
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#homeIndex {
  width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  .userMsg {
    margin-top: 40px;
    height: 136px;
    background-color: #fff;
    display: flex;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    .imgs {
      width: 92px;
      img {
        width: 72px;
        height: 72px;
        border-radius: 50%;
      }
    }
    .msg {
      padding-top: 10px;
      flex: 1;
      & > div {
        margin-bottom: 10px;
      }
      .names {
        font-size: 16px;
        font-weight: 800;
      }
      .people {
        cursor: pointer;
        margin-left: 14px;
        color: #4387f8;
        svg {
          position: relative;
          top: 2px;
        }
      }
    }
    .header-info-flex {
      display: flex;
      justify-content: space-between;
    }
    .mgSelf {
      display: flex;
      justify-content: space-between;
      .icons {
        color: red;
        margin-left: 15px;
        cursor: pointer;
      }
      .iconq {
        margin-left: 15px;
      }
    }
    .flexBu {
      display: flex;
      justify-content: space-between;
      .qiye {
        font-size: 12px;
        color: #4387f8;
        cursor: pointer;
      }
      .icons {
        color: red;
        margin-left: 15px;
        cursor: pointer;
      }
      .ask {
        display: flex;
        & > div:nth-child(1) {
          width: 28px;
          height: 28px;
          border-radius: 50%;
          background-color: rgba(248, 186, 67, 0.6);
          text-align: center;
          i {
            font-size: 16px;
            line-height: 28px;
            color: #fff;
          }
        }
        span {
          margin-left: 5px;
          line-height: 28px;
        }
        & > div:nth-child(3) {
          margin-left: 30px;
          width: 28px;
          height: 28px;
          border-radius: 50%;
          background-color: rgba(67, 135, 248, 0.6);
          text-align: center;
          i {
            font-size: 16px;
            line-height: 28px;
            color: #fff;
          }
        }
      }
      .others {
        font-size: 12px;
        span:nth-child(2) {
          margin-left: 16px;
        }
      }
    }
  }
  .qiyeJob {
    border-radius: 5px;
    background-color: #fff;
    padding: 20px;
    .myAdmin {
      margin-top: 12px;
      color: #999;
      font-size: 12px;
      margin-bottom: 16px;
    }
    .adminsQi {
      padding: 18px 20px;
      margin-bottom: 10px;
      height: 72px;
      width: 778px;
      box-sizing: border-box;
      display: flex;
      background-color: #fafafa;
      cursor: pointer;
      &:hover {
        background-color: rgba(#a0cded, 0.1);
        .main-btn {
          display: block;
        }
      }
      & > div:nth-child(1) {
        width: 36px;
        text-align: center;
        svg {
          position: relative;
          top: 5px;
        }
      }
      .icon-company {
        margin-right: 8px;
      }
      .main-btn {
        color: #4387f8;
        font-size: 12px;
        cursor: pointer;
        display: none;
      }
      & > div:nth-child(2) {
        flex: 1;
        overflow: hidden;
        height: 44px;
        & > div:nth-child(1) {
          font-size: 12px;
          line-height: 40px;
        }
        & > div:nth-child(2) {
          margin-top: 8px;
          font-size: 14px;
          line-height: 14px;
        }
      }
      & > div:nth-child(3) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 360px;
        font-size: 26px;
        color: #999;
      }
    }
  }
}

.titls {
  display: flex;
  justify-content: space-between;
  & > span:nth-child(1) {
    font-size: 16px;
    font-weight: 800;
  }

  & > span:nth-child(2) {
    font-size: 14px;
    color: #4387f8;
    user-select: none;
    cursor: pointer;
  }
}
.setMain {
  cursor: pointer;
  &:hover {
    color: #4387f8;
  }
}
.iconsd {
  cursor: pointer;
}
.blues {
  color: #4387f8;
}
</style>
